import AddressInformation from './AddressInformation';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  setCurbside,
  setCurbsideOutfitInfo,
  setDeliveryAddress,
  setDeliveryAddressFromForm,
  setDispatchTime,
  setDispatchType,
} from '../../../../state/checkout/checkout.actions';
import { getCurbsideInfo, getFormattedAddressWithComment } from '@wix/restaurants-client-logic';
import { AppState } from '../../../../state/createStore';
import { EMPTY_ADDRESS, Modals } from '../../../../core/constants';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import {
  multiLocationSupportedDispatchTypesSelector,
  supportedDispatchTypesSelector,
} from '../../../../state/selectors/supportedDispatchTypesSelector';
import { withExperiments } from 'yoshi-flow-editor-runtime';
import {
  setAddressInputError,
  setErrorVisibility,
  setFieldError,
  toggleAllErrors,
} from '../../../../state/addressInformationForm/addressForm.actions';
import { deliveryAreaSelectorByAddressForm } from '../../../../state/selectors/deliveryAreaSelector';
import { openModal, saveAddressToServer, saveStateToSessionStorage } from '../../../../state/session/session.actions';
import { businessNotificationSelector } from '../../../../state/selectors/businessNotificationSelector';

function mapStateToProps(state: AppState, ownProps: any) {
  const { priceComponents, displayableOrderItems } = cartSummarySelector(state);
  const { restaurant } = state.session;
  const idealDeliveryArea = deliveryAreaSelectorByAddressForm(state);
  const businessNotification = businessNotificationSelector(state);
  const curbsideInfo = getCurbsideInfo(state.session.restaurant.deliveryInfos);
  const isMLEnabled = ownProps.experiments.enabled('specs.restaurants.olo-client-ml');

  return {
    ...ownProps,
    dispatchType: state.checkout.dispatch.type,
    address: state.checkout.dispatch.type === 'delivery' ? state.checkout.dispatch.address : EMPTY_ADDRESS,
    restaurant,
    totalOrderPrice: priceComponents.total,
    dispatchTime: state.checkout.dispatch.time,
    addressInputError: state.checkout.dispatch.type === 'delivery' ? state.addressForm.addressInputError : undefined,
    fieldsErrors: state.addressForm.fieldErrors,
    supportedDispatchTypes: isMLEnabled
      ? multiLocationSupportedDispatchTypesSelector(state)
      : supportedDispatchTypesSelector(state),
    formattedAddressWithComment: getFormattedAddressWithComment(restaurant.address),
    isMobile: state.platformParams.isMobile,
    selectedAddressOption: state.addressForm.selectedAddressOption,
    idealDeliveryArea: idealDeliveryArea?.dispatchInfo,
    errorOrderItem: displayableOrderItems.find((orderItem) => orderItem.errors.length || orderItem.isDishDeleted),
    timezone: state.session.restaurant.timezone,
    errorsVisibility: state.addressForm.errorsVisibility,
    deliveryInfos: restaurant.deliveryInfos,
    businessNotification,
    locale: state.session.restaurant.locale,
    isRTL: state.platformParams.isRTL,
    savedAddresses: state.session.savedAddresses,
    isLoadingAddressesFromServer: state.session.isLoadingAddressesFromServer,
    initialCurbside: state.checkout.dispatch.type === 'takeout' ? state.checkout.dispatch.isCurbside : undefined,
    initialCurbsideOutfitInfo:
      state.checkout.dispatch.type === 'takeout' ? state.checkout.dispatch.curbsideAdditionalInformation : undefined,
    curbsideInfo,
    locations: state.session.locations,
    isAddressSelectionModalOpen: Boolean(state.session.modals.find((m) => m.modal === Modals.ADDRESS_SELECTION)),
    isUserLoggedIn: state.session.isUserLoggedIn,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setDispatchType,
      setDeliveryAddress,
      setDispatchTime,
      toggleAllErrors,
      setDeliveryAddressFromForm,
      setAddressInputError,
      setFieldError,
      saveStateToSessionStorage,
      setErrorVisibility,
      openModal,
      saveAddressToServer,
      setCurbside,
      setCurbsideOutfitInfo,
    },
    dispatch,
  );
}

export default withExperiments(connect(mapStateToProps, mapDispatchToProps)(AddressInformation));
