import React from 'react';
import Text from '../../../core-components/Text';
import styles from '../AddressInformation.scss';
import dataHooks from '../../../data-hooks';
import { TranslationFunction } from 'i18next';
import { translate } from 'yoshi-flow-editor-runtime';
import { Address, extractLocalizedString } from '@wix/restaurants-client-logic';
import { Element } from 'react-scroll';
import Dropdown from '../../Dropdown';
import { PartialLocation } from '../../../../../core/oloApi';
import { OpenModalPayload } from '../../../../../state/session/session.actions.types';
import { Modals } from '../../../../../core/constants';

interface AddressInformationTakeoutProps {
  formattedAddressWithComment: string;
  address: Address;
  t: TranslationFunction;
  locations: PartialLocation[];
  currentLocationId?: string;
  openModal: (payload: OpenModalPayload) => void;
}

const RestaurantTakeoutDetails: React.FC<AddressInformationTakeoutProps> = ({
  formattedAddressWithComment,
  t,
  locations = [],
  currentLocationId,
  openModal,
}) => {
  if (locations.length > 0) {
    return (
      <label className={styles.addressWrapper}>
        <Element name="address-input">
          <Text typography="p2-s" className={styles.label}>
            {t('order_settings_modal_pickup_location_label')}
          </Text>
          <Dropdown
            upgrade
            data-hook={dataHooks.addressInformationChooseLocationDropdown}
            options={locations.map((location) => ({
              id: location.currentLocationId,
              value: extractLocalizedString(location.locationName ?? {}, location.locale),
              subtitle: location.address.formatted,
              isSelectable: true,
            }))}
            initialSelectedId={currentLocationId}
            onChange={({ id }) => {
              openModal({
                modal: Modals.CHANGE_LOCATION_MODAL,
                context: { locationId: id },
              });
            }}
            mobileNativeSelect
          />
        </Element>
      </label>
    );
  } else {
    return (
      <React.Fragment>
        <Text typography="p2-s" className={styles.restaurantAddressLabel}>
          {t('order_settings_modal_pickup_label')}
        </Text>
        <Text
          typography="p2-m"
          data-hook={dataHooks.addressInformationTakeoutAddress}
          className={styles.restaurantAddress}
        >
          {formattedAddressWithComment}
        </Text>
      </React.Fragment>
    );
  }
};

RestaurantTakeoutDetails.displayName = 'RestaurantTakeoutDetails';

export default translate()(RestaurantTakeoutDetails);
