import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import dataHooks from '../../data-hooks';
import React from 'react';
import Text from '../../core-components/Text';
import { TEXT_BUTTON_PRIORITY, TextButton } from '../TextButton';
import styles from './MemberLoginCTA.scss';
import { Trans, translate } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';

interface MemberLoginCTAProps {
  onLoginClick: () => void;
  onLogoutClick: () => void;
  userEmail?: string;
  isUserLoggedIn?: boolean;
  isMobile?: boolean;
  t: TranslationFunction;
}

const ColorlessTextButton: React.FC<{ onClick: () => void; children: React.ReactNode }> = ({ onClick, children }) => {
  return (
    <TextButton colorless priority={TEXT_BUTTON_PRIORITY.link} onClick={onClick} data-hook={dataHooks.memberCTALogin}>
      <Text typography="p2-s-colorless">{children}</Text>
    </TextButton>
  );
};

const MemberLoginCTA: React.FC<MemberLoginCTAProps> = ({
  onLoginClick,
  onLogoutClick,
  userEmail,
  isUserLoggedIn,
  isMobile,
  t,
}) => {
  const Logout = () => (
    <TextButton
      colorless
      priority={TEXT_BUTTON_PRIORITY.link}
      onClick={onLogoutClick}
      data-hook={dataHooks.memberCTALogout}
    >
      <Text typography="p2-s-colorless"> {t('checkout_main_loginout_existingaccount.logout.cta')}</Text>
    </TextButton>
  );
  const content = !isUserLoggedIn ? (
    <div>
      <Text typography="p2-s-colorless">
        <Trans
          t={t}
          i18nKey="checkout_main_loginout_existingaccount.login.text"
          components={[<ColorlessTextButton onClick={onLoginClick}>placeholder</ColorlessTextButton>]}
        />
      </Text>
    </div>
  ) : (
    <>
      <Text typography="p2-s-colorless">
        {t('checkout_main_loginout_existingaccount.loggedin.text', { email_address: userEmail })}
      </Text>
      {isMobile && (
        <>
          <span>{'  '}</span>
          <Logout />
        </>
      )}
    </>
  );

  return (
    <div className={styles.wrapper}>
      <SectionNotification data-hook={dataHooks.memberCTASection}>
        <SectionNotification.Text>{content}</SectionNotification.Text>
        {isUserLoggedIn && !isMobile && <Logout />}
      </SectionNotification>
    </div>
  );
};

MemberLoginCTA.displayName = 'MemberLoginCTA';

export default translate()(MemberLoginCTA);
