import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Divider } from 'wix-ui-tpa/Divider';
import { scroller } from 'react-scroll';
import { Modals } from '../../../../core/constants';
import { SetCheckoutStepPayload } from '../../../../state/checkout/checkout.actions.types';
import { CloseModalPayload, OpenModalPayload } from '../../../../state/session/session.actions.types';
import ContactInformation from '../ContactInformation';
import dataHooks from '../../data-hooks';
import { calculateTopValue } from '../Scroller/scrollingUtils';
import Cart from '../Cart';
import styles from './CheckoutFlow.scss';
import AddressInformation from '../AddressInformation';
import CheckoutPayments from '../CheckoutPayments';
import Sticky from '../Sticky';
import CheckoutReview from '../CheckoutReview';
import Text from '../../core-components/Text';
import { TEXT_BUTTON_PRIORITY, TextButton } from '../TextButton';
import { Contact, DispatchType } from '@wix/restaurants-client-logic';
import { CheckoutStep } from '../../../../core/types/Checkout';
import { TranslationFunction } from 'i18next';
import CheckoutSubmit from '../CheckoutSubmit';
import { useBi, useEnvironment, useExperiments } from 'yoshi-flow-editor-runtime';
import Spinner from '../Spinner';
import MemberLoginCTA from '../MemberLoginCTA';

export interface CheckoutFlowProps {
  contact: Contact;
  dispatchType: DispatchType;
  isMobile?: boolean;
  t: TranslationFunction;
  shouldGoBack: boolean;
  step: CheckoutStep;
  setStep: (payload: SetCheckoutStepPayload) => void;
  closeModal: (payload: CloseModalPayload) => void;
  initialContact: Contact;
  isMemberContactLoading: boolean;
  isUserLoggedIn: boolean;
  loginMember: any;
  openModal: (payload: OpenModalPayload) => void;
  hasMembersAreaIntegration?: boolean;
  loggedInUserEmail?: string;
}

const orderedSteps: CheckoutStep[] = ['address-information', 'contact-information', 'payments', 'review'];

function isAfter(currentStep: CheckoutStep, compareTo: CheckoutStep) {
  return orderedSteps.indexOf(currentStep) > orderedSteps.indexOf(compareTo);
}

function isBefore(currentStep: CheckoutStep, compareTo: CheckoutStep) {
  return orderedSteps.indexOf(currentStep) < orderedSteps.indexOf(compareTo);
}

export const getScrollOptions = () => {
  return {
    smooth: 'easeInOutCubic',
    duration: 200,
    offset: -calculateTopValue(0) - 30,
  };
};

function useEnvironmentSafe() {
  try {
    return useEnvironment();
  } catch (e) {
    return { isPreview: false };
  }
}

const CheckoutFlow: React.FC<CheckoutFlowProps> = ({
  isMobile,
  shouldGoBack,
  t,
  step,
  setStep,
  closeModal,
  initialContact,
  isUserLoggedIn,
  loginMember,
  hasMembersAreaIntegration,
  openModal,
  isMemberContactLoading,
  loggedInUserEmail,
}) => {
  const goToAddressInformation = () => setStep({ step: 'address-information' });
  const goToContactInformation = () => setStep({ step: 'contact-information' });
  const goToPayments = () => setStep({ step: 'payments' });
  const goToReview = () => setStep({ step: 'review' });
  const biLogger = useBi();
  const history = useHistory();
  const { isPreview } = useEnvironmentSafe();

  const resetCheckoutStep = () => setStep({ step: 'address-information' });
  useEffect(() => {
    if (!isPreview && shouldGoBack) {
      history.replace('/');
    }
  }, [shouldGoBack, history, isPreview]);
  const checkoutReviewCollapsed = isBefore(step, 'review');

  useEffect(() => {
    closeModal({ modal: Modals.CART_MODAL });
    scroller.scrollTo('checkout-flow', getScrollOptions());
  }, [closeModal]);

  const sendBiEditStep = (checkoutStep: CheckoutStep) => {
    biLogger.checkoutEdit({
      type: checkoutStep,
    });
  };

  const titleId = 'restaurants.checkout-flow.title';

  const { experiments } = useExperiments();
  const shouldMemberCTA =
    (experiments.enabled('specs.restaurants.olo-client-members-area-contact') ||
      experiments.enabled('specs.restaurants.olo-client-members-area-my-wallet') ||
      experiments.enabled('specs.restaurants.olo-client-members-area-addresses')) &&
    hasMembersAreaIntegration;
  return (
    <div
      id="checkout-flow"
      className={`${styles.wrapper} ${isMobile && styles.mobile}`}
      data-hook={dataHooks.checkoutFlow}
    >
      <div className={styles.title}>
        <Text id={titleId} typography="header-l">
          {t('checkout_main_checkoutsection_title')}
        </Text>
        <Link
          to="/"
          onClick={() => {
            biLogger.checkoutBackToMenu({});
            resetCheckoutStep();
            window.scrollTo(0, 0);
          }}
          tabIndex={-1}
        >
          <TextButton priority={TEXT_BUTTON_PRIORITY.primary} data-hook={dataHooks.checkoutFlowBackToMenu}>
            <Text typography="p2-m-colorless">{t('checkout_main_back_to_menu')}</Text>
          </TextButton>
        </Link>
      </div>

      <div className={styles.primaryContent}>
        {shouldMemberCTA && (
          <div className={styles.primaryContent}>
            <MemberLoginCTA
              isMobile={isMobile}
              isUserLoggedIn={isUserLoggedIn}
              onLoginClick={() => loginMember()}
              userEmail={loggedInUserEmail}
              onLogoutClick={() => openModal({ modal: Modals.LOGOUT_MODAL })}
            />
          </div>
        )}

        <AddressInformation
          index={String(orderedSteps.indexOf('address-information') + 1)}
          onSubmit={goToContactInformation}
          done={isAfter(step, 'address-information')}
          collapsed={isBefore(step, 'address-information')}
          onEdit={() => {
            sendBiEditStep('address-information');
            goToAddressInformation();
          }}
          describedby={titleId}
        />
        <div className={styles.dividerWrapper}>
          <Divider className={styles.divider} />
        </div>
        {isMemberContactLoading && isBefore(step, 'contact-information') ? (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) : (
          <ContactInformation
            index={String(orderedSteps.indexOf('contact-information') + 1)}
            onSubmit={goToPayments}
            done={isAfter(step, 'contact-information')}
            collapsed={isBefore(step, 'contact-information')}
            onEdit={() => {
              sendBiEditStep('contact-information');
              goToContactInformation();
            }}
            initialContact={initialContact}
            describedby={titleId}
          />
        )}
        <div className={styles.dividerWrapper}>
          <Divider className={styles.divider} />
        </div>
        <CheckoutPayments
          index={String(orderedSteps.indexOf('payments') + 1)}
          onSubmit={goToReview}
          done={isAfter(step, 'payments')}
          collapsed={isBefore(step, 'payments')}
          onEdit={() => {
            sendBiEditStep('payments');
            goToPayments();
          }}
          step={step}
          describedby={titleId}
        />
        <div className={styles.dividerWrapper}>
          <Divider className={styles.divider} />
        </div>
        <CheckoutReview
          index={String(orderedSteps.indexOf('review') + 1)}
          collapsed={checkoutReviewCollapsed}
          describedby={titleId}
        />
        {isMobile && (
          <div data-hook={dataHooks.checkoutFlowCartWrapper}>
            <Cart className={styles.cartWrapperMobile} shortVersion displayTitle isCartImmutable />
          </div>
        )}
        {!checkoutReviewCollapsed && <CheckoutSubmit />}
      </div>
      {!isMobile && (
        <div className={styles.secondaryContent}>
          <Sticky offset={24}>
            <div className={styles.cartWrapper} data-hook={dataHooks.checkoutFlowCartWrapper}>
              <Cart hideMinOrderPrice displayTitle isCartImmutable />
            </div>
          </Sticky>
        </div>
      )}
    </div>
  );
};

CheckoutFlow.displayName = 'CheckoutFlow';

export default CheckoutFlow;
