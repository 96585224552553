import React, { useState, SyntheticEvent, useEffect } from 'react';
import { scroller, Element } from 'react-scroll';
import { TranslationFunction } from 'i18next';
import { Spinner } from 'wix-ui-tpa/Spinner';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { SetContactFieldPayload, SetContactPayload } from '../../../../state/checkout/checkout.actions.types';
import styles from './ContactInformation.scss';
import Text from '../../core-components/Text';
import usePhoneUtils from '../../../../core/hooks/usePhoneUtils';
import dataHooks from '../../data-hooks';
import ContactInformationInput from './ContactInformationInput';
import CheckoutFlowStepTitle from '../CheckoutFlowStepTitle';
import { EmailValidator } from 'commons-validator-js';
import { useBi, translate, useExperiments } from 'yoshi-flow-editor-runtime';
import Button from '../Button';
import Scroller from '../Scroller';
import { Contact, EMPTY_CONTACT } from '@wix/restaurants-client-logic';
import ContactPhoneNumberInput from './ContactPhoneNumberInput';
import { getScrollOptions } from '../CheckoutFlow/CheckoutFlow';
import Checkbox from '../Checkbox';
import { componentSettings } from '../../componentSettings';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';

export interface ContactInformationProps {
  initialContact?: Contact;
  countryCode?: string;
  setContact: (payload: SetContactPayload) => void;
  done?: boolean;
  collapsed?: boolean;
  forceErrorVisibility?: boolean;
  index?: string;
  onSubmit: () => void;
  onEdit: () => void;
  t: TranslationFunction;
  saveStateToSessionStorage: () => void;
  describedby?: string;
  isUserLoggedIn: boolean;
  hasMembersAreaIntegration: boolean;
}

function validateString(str: any, field: string, t: TranslationFunction) {
  return str
    ? ''
    : field === 'firstName'
    ? t('checkout_main_delivery_contactinfo_firstname_errormessage')
    : t('checkout_main_delivery_contactinfo_lastname_errormessage');
}

function validateEmail(email: string, t: TranslationFunction) {
  const isValid = new EmailValidator().isValid(email);
  return isValid ? '' : t('checkout_main_delivery_contactinfo_email_errormessage');
}

function validatePhone(PhoneUtils: any, phoneNumber: string, countryCode: string, t: TranslationFunction) {
  let errorMessage = '';
  if (!countryCode) {
    errorMessage = 'The restaurant address is invalid';
  }
  if (!PhoneUtils.validatePhone(phoneNumber, countryCode)) {
    errorMessage = t('checkout_main_delivery_contactinfo_phone_errormessage');
  }
  return errorMessage;
}

const ContactInformation: React.FC<ContactInformationProps> = ({
  initialContact = EMPTY_CONTACT,
  countryCode,
  setContact,
  collapsed,
  done,
  index = '',
  onSubmit,
  forceErrorVisibility,
  onEdit,
  t,
  saveStateToSessionStorage,
  hasMembersAreaIntegration,
  describedby,
  isUserLoggedIn,
}) => {
  const biLogger = useBi();

  const PhoneUtils = usePhoneUtils();

  const settings = useSettings();

  const [localContact, setLocalContact] = useState(initialContact);

  const [shouldSaveContact, setShouldSaveContact] = useState(false);

  const [isWaiting, setIsWaiting] = useState(false);

  const [validations, setValidations] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const [errorVisibility, setErrorVisibility] = useState({
    firstName: Boolean(forceErrorVisibility),
    lastName: Boolean(forceErrorVisibility),
    email: Boolean(forceErrorVisibility),
    phone: Boolean(forceErrorVisibility),
  });
  const { experiments } = useExperiments();
  useEffect(() => {
    if (errorVisibility.firstName && validations.firstName) {
      scroller.scrollTo('firstName', getScrollOptions());
    } else if (errorVisibility.lastName && validations.lastName) {
      scroller.scrollTo('lastName', getScrollOptions());
    } else if (errorVisibility.phone && validations.phone) {
      scroller.scrollTo('phone', getScrollOptions());
    } else if (errorVisibility.email && validations.email) {
      scroller.scrollTo('email', getScrollOptions());
    }
  }, [
    validations.firstName,
    validations.lastName,
    validations.phone,
    validations.email,
    errorVisibility.firstName,
    errorVisibility.lastName,
    errorVisibility.phone,
    errorVisibility.email,
  ]);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setIsWaiting(true);

    setErrorVisibility({
      firstName: true,
      lastName: true,
      email: true,
      phone: true,
    });

    const newValidations = {
      firstName: validateString(localContact.firstName, 'firstName', t),
      lastName: validateString(localContact.lastName, 'lastName', t),
      email: validateEmail(localContact.email, t),
      phone: validatePhone(PhoneUtils, localContact.phone, countryCode || '', t),
    };
    setValidations(newValidations);
    if (Object.values(newValidations).every((v) => !v)) {
      const emailDomain = localContact.email.substring(localContact.email.indexOf('@') + 1);
      biLogger.contactInformationContinue({ emailDomain });

      setContact({ contact: localContact, shouldSaveToMembers: shouldSaveContact });
      onSubmit();
      saveStateToSessionStorage();
    } else {
      const errors = Object.values(newValidations)
        .filter((v) => v)
        .join();
      biLogger.contactInformationContinueValidationError({ errorReason: errors });
    }
    setIsWaiting(false);
  };

  const getErrorMessage = (field: keyof typeof validations) => {
    return errorVisibility[field] ? validations[field] : '';
  };

  const handleInputChange = ({ field, value }: SetContactFieldPayload) => {
    setErrorVisibility((v) => ({ ...v, [field]: false }));
    setLocalContact({ ...localContact, [field]: value });
  };

  const titleId = `${dataHooks.contactInformation}-title`;
  const onSaveCheckboxClick = () => {
    biLogger.checkboxSaveContactForFutureOrders({ value: !shouldSaveContact ? 'on' : 'off' });
    setShouldSaveContact(!shouldSaveContact);
  };
  const shouldShowSaveCheckbox =
    experiments.enabled('specs.restaurants.olo-client-members-area-contact') &&
    hasMembersAreaIntegration &&
    isUserLoggedIn;
  const isInitialContact = () => initialContact?.lastName || initialContact?.firstName || initialContact?.phone;
  return (
    <Scroller name="contact-information" condition={!collapsed && !done}>
      <div
        data-hook={dataHooks.contactInformation}
        aria-labelledby={titleId}
        className={styles.wrapper}
        aria-describedby={describedby}
      >
        <CheckoutFlowStepTitle
          text={t('checkout_main_contactinfo_title')}
          done={done}
          collapsed={collapsed}
          index={index}
          onEdit={onEdit}
          editButtonDataHook={dataHooks.checkoutSummaryLineEditContact}
          titleId={titleId}
        />

        {!collapsed && !done && !PhoneUtils && <Spinner />}

        {!collapsed && !done && PhoneUtils && (
          <form noValidate className={styles.form} onSubmit={handleSubmit} data-hook={dataHooks.contactInformationForm}>
            <div className={styles.inputs}>
              <Element name={'firstName'}>
                <ContactInformationInput
                  contact={localContact}
                  field="firstName"
                  label={t('checkout_main_contactinfo_firstname_title')}
                  data-hook={dataHooks.checkoutFlowFirstNameInput}
                  onChange={handleInputChange}
                  errorMessage={getErrorMessage('firstName')}
                  autoFocus
                />
              </Element>
              <Element name={'lastName'}>
                <ContactInformationInput
                  contact={localContact}
                  field="lastName"
                  label={t('checkout_main_contactinfo_lastname_title')}
                  data-hook={dataHooks.checkoutFlowLastNameInput}
                  onChange={handleInputChange}
                  errorMessage={getErrorMessage('lastName')}
                />
              </Element>
              <Element name={'phone'}>
                <ContactPhoneNumberInput
                  type="tel"
                  contact={localContact}
                  field="phone"
                  label={t('checkout_main_contactinfo_phone_title')}
                  data-hook={dataHooks.checkoutFlowPhoneInput}
                  onChange={handleInputChange}
                  errorMessage={getErrorMessage('phone')}
                />
              </Element>
              <Element name={'email'}>
                <ContactInformationInput
                  disable={shouldShowSaveCheckbox}
                  type="email"
                  contact={localContact}
                  field="email"
                  label={t('checkout_main_contactinfo_email_title')}
                  data-hook={dataHooks.checkoutFlowEmailInput}
                  onChange={handleInputChange}
                  errorMessage={getErrorMessage('email')}
                />
              </Element>
            </div>
            {shouldShowSaveCheckbox && (
              <Checkbox
                label={
                  isInitialContact()
                    ? t('checkout_main_contactinfo_overridedetails_checkbox')
                    : t('checkout_main_contactinfo_saveinfo.checkbox.text')
                }
                onChange={onSaveCheckboxClick}
                checked={shouldSaveContact}
                data-hook={dataHooks.addressInformationSaveContactCheckbox}
                name={dataHooks.addressInformationSaveContactCheckbox}
                className={styles.checkbox}
              />
            )}

            <Button
              upgrade
              fullWidth
              priority={PRIORITY.primary}
              className={styles.button}
              data-hook={dataHooks.contactInformationContinue}
              type="submit"
              disabled={isWaiting}
            >
              <Text typography="p2-m-colorless">{t('checkout_main_button_continue')}</Text>
            </Button>
          </form>
        )}

        {done && (
          <div className={styles.summaryWrapper}>
            <Text
              typography="p2-m-secondary"
              className={styles.summaryLine}
              data-hook={dataHooks.contactInformationSummaryLine}
            >
              {localContact.firstName} {localContact.lastName}
            </Text>
            <Text
              typography="p2-m-secondary"
              className={styles.summaryLine}
              data-hook={dataHooks.contactInformationSummaryLine}
            >
              {localContact.phone}
            </Text>
            <Text
              typography="p2-m-secondary"
              className={styles.summaryLine}
              data-hook={dataHooks.contactInformationSummaryLine}
            >
              {localContact.email}
            </Text>
          </div>
        )}
      </div>
    </Scroller>
  );
};

ContactInformation.displayName = 'ContactInformation';

export default translate()(ContactInformation);
