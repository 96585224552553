import { createSelector } from 'reselect';
import { AppState } from '../createStore';
import { getSupportedDispatchTypes } from '@wix/restaurants-client-logic';
import { getDeliveryDisplayableMenuSelector } from './menuViewSelector';

export const supportedDispatchTypesSelector = createSelector(
  [
    (state: AppState) => state.session.restaurant.deliveryInfos,
    (state: AppState) => getDeliveryDisplayableMenuSelector(state),
  ],
  (deliveryInfos, deliveryDisplayableMenu) => getSupportedDispatchTypes({ deliveryInfos, deliveryDisplayableMenu }),
);

export const multiLocationSupportedDispatchTypesSelector = createSelector(
  [(state: AppState) => state.session.locations.flatMap((location) => location.deliveryInfos)],
  (deliveryInfos) => getSupportedDispatchTypes({ deliveryInfos }),
);
