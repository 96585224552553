import MenuView from './MenuView';
import { connect } from 'react-redux';
import { Modals } from '../../../../core/constants';
import { AppState } from '../../../../state/createStore';
import { getDisplayableMenuSelector } from '../../../../state/selectors/menuViewSelector';
import { openModal, closeModal } from '../../../../state/session/session.actions';
import { bindActionCreators, Dispatch } from 'redux';
import { businessNotificationSelector } from '../../../../state/selectors/businessNotificationSelector';

function mapStateToProps(state: AppState) {
  const businessNotification = businessNotificationSelector(state);

  return {
    displayableMenu: getDisplayableMenuSelector(state),
    orderItemsCount: state.cart.orderItems.length,
    isMobile: state.platformParams.isMobile,
    layout: state.platformParams.settings.layout,
    menuDisplayOptions: {
      hideDishImages: !state.platformParams.settings.showDishImages,
      hideSectionImages: !state.platformParams.settings.showSectionImages,
      hideDishDescriptions: !state.platformParams.settings.showDescriptions,
      hideMenuItemLabels: !state.platformParams.settings.showLabels,
    },
    isRTL: state.platformParams.isRTL,
    isCartModalOpen: !!state.session.modals.find(({ modal }) => modal === Modals.CART_MODAL),
    notification: businessNotification?.notification,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      openModal,
      closeModal,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuView);
